<template>
    <div>
        <p><strong>NO PURCHASE NECESSARY.</strong> 
        The Stouffer Family Excellence in Foodservice Award Contest is
        open only to Foodservice Operators who reside in the 50 U.S. or D.C., and are 18 years of age or
        older (or 19 or older for residents of AL and NE). Void where prohibited. Entry Period for the
        Contest begins at 12:01:00 AM EST on April 20, 2022 and ends at 11:59:59 PM EST on June 30, 
        2022. Subject to Official Rules at <a href="www.StouffersFSExcellenceAward.com" target="_blank">www.StouffersFSExcellenceAward.com.</a>. Sponsor: Nestle USA
        1812 N. Moore St. Arlington, VA 22209
        </p>
    </div>
</template>

<script>
export default {
    setup() {
        
    },
}
</script>