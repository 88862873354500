<template>
    <div>
            <div class="form-container">
                <div class="form-element" v-bind:class="{error: errors.first_name}">
                    <label for="first_name">First Name*</label>
                    <input name="first_name" type="text" id="first_name" v-model="entry.first_name" 
                        v-on:input="clearError('first_name')">
                    <div v-if="errors.first_name">
                        <p>{{ errors.first_name[0] }}</p>
                    </div>
                </div>
                <div class="form-element" v-bind:class="{error: errors.last_name}">
                    <label for="last_name">Last Name*</label>
                    <input name="last_name" type="text" id="last_name" v-model="entry.last_name" 
                        v-on:input="clearError('last_name')">
                    <div v-if="errors.last_name">
                        <p>{{ errors.last_name[0] }}</p>
                    </div>
                </div>
                <div class="form-element" v-bind:class="{error: errors.title}">
                    <label for="title">Title/Position*</label>
                    <input name="title" type="text" id="title" v-model="entry.title" 
                        v-on:input="clearError('title')">
                    <div v-if="errors.title">
                        <p>{{ errors.title[0] }}</p>
                    </div>
                </div>

                <div class="form-element" v-bind:class="{error: errors.business_name}">
                    <label for="business_name">Business Name*</label>
                    <input name="business_name" type="text" id="business_name" v-model="entry.business_name" 
                        v-on:input="clearError('business_name')">
                    <div v-if="errors.business_name">
                        <p>{{ errors.business_name[0] }}</p>
                    </div>
                </div>
                <div class="form-element" v-bind:class="{error: errors.type_of_operation}">
                        <label for="type_of_operation">Type of Operation*</label>
                    <input name="type_of_operation" type="text" id="type_of_operation" v-model="entry.type_of_operation" 
                        v-on:input="clearError('type_of_operation')">
                    <div v-if="errors.type_of_operation">
                        <p>{{ errors.type_of_operation[0] }}</p>
                    </div>
                </div>
            </div>

            <div class="form-container street-address">
                <div class="form-element" v-bind:class="{error: errors.business_address}">
                    <label for="business_address">Business Address*</label>
                    <input name="business_address" type="text" id="business_address" v-model="entry.business_address" 
                        v-on:input="clearError('business_address')">
                    <div v-if="errors.business_address">
                        <p>{{ errors.business_address[0] }}</p>
                    </div>
                </div>
            </div>
            <div class="form-container state-address">
                <div class="form-element" v-bind:class="{error: errors.city}">
                    <label for="city">City*</label>
                    <input name="city" type="text" id="city" v-model="entry.city" 
                        v-on:input="clearError('city')">
                    <div v-if="errors.city">
                        <p>{{ errors.city[0] }}</p>
                    </div>
                </div>
                <div class="form-element" v-bind:class="{error: errors.state_code}">
                    <label for="state_code">State*</label>
                    <select name="state_code" id="state_code" v-model="entry.state_code" v-on:input="clearError('state_code')">
                        <option value="">Select</option>
                        <option class="font-smd black" v-for="index, value in states" :value="value" :key="index">{{ index }}</option>
                    </select>
                    <div v-if="errors.state_code">
                        <p>{{ errors.state_code[0] }}</p>
                    </div>
                </div>

                <div class="form-element" v-bind:class="{error: errors.zip}">
                    <label for="zip">Zip*</label>
                    <input name="zip" type="text" id="zip" v-model="entry.zip" 
                        v-on:input="clearError('zip')">
                    <div v-if="errors.zip">
                        <p>{{ errors.zip[0] }}</p>
                    </div>
                </div>
            </div>
            <div class="form-container">
                <div class="form-element" v-bind:class="{error: errors.distributor_name}">
                    <label for="distributor_name">Distributor Name*</label>
                    <input name="distributor_name" type="text" id="distributor_name" v-model="entry.distributor_name" 
                        v-on:input="clearError('distributor_name')">
                    <div v-if="errors.distributor_name">
                        <p>{{ errors.distributor_name[0] }}</p>
                    </div>
                </div>
                <div class="form-element" v-bind:class="{error: errors.email}">
                    <label for="email">Email*</label>
                    <input name="email" type="text" id="email" v-model="entry.email" 
                        v-on:input="clearError('email')">
                    <div v-if="errors.email">
                        <p>{{ errors.email[0] }}</p>
                    </div>
                </div>
                <div class="form-element" v-bind:class="{error: errors.phone}">
                    <label for="phone">Phone Number</label>
                    <input name="phone" type="text" id="phone" v-model="entry.phone"
                        @keypress="isNumber($event)"
                        v-on:input="clearError('phone')">
                    <div v-if="errors.phone">
                        <p>{{ errors.phone[0] }}</p>
                    </div>
                </div>
            </div>
            <div class="form-container street-address">
                <div class="form-element" v-bind:class="{error: errors.prize_thoughts}">
                    <label for="prize_thoughts">Any thoughts on how you might use the grand prize to further your foodservice success should you win?</label>
                    <input name="prize_thoughts" type="text" id="prize_thoughts" v-model="entry.prize_thoughts" 
                        v-on:input="clearError('prize_thoughts')">
                    <div v-if="errors.prize_thoughts">
                        <p>{{ errors.prize_thoughts[0] }}</p>
                    </div>
                </div>
            </div>
            <div class="form-container street-address">
                <div class="form-element" v-bind:class="{error: errors.how_stouffer_helped}">
                    <label for="how_stouffer_helped">How has <span class="font-italic">Stouffer’s</span> helped your business thrive?</label>
                    <input name="how_stouffer_helped" type="text" id="how_stouffer_helped" v-model="entry.how_stouffer_helped" 
                        v-on:input="clearError('helped')">
                    <div v-if="errors.how_stouffer_helped">
                        <p>{{ errors.how_stouffer_helped[0] }}</p>
                    </div>
                </div>
            </div>
            <div class="form-container street-address">
                <div class="form-element" v-bind:class="{error: errors.favorite_foodservice_product}">
                    <label for="favorite_foodservice_product">What is your favorite <span class="font-italic">Stouffer’s</span> foodservice product?</label>
                    <input name="favorite_foodservice_product" type="text" id="product" v-model="entry.favorite_foodservice_product" 
                        v-on:input="clearError('favorite_foodservice_product')">
                    <div v-if="errors.favorite_foodservice_product">
                        <p>{{ errors.favorite_foodservice_product[0] }}</p>
                    </div>
                </div>
            </div>
            <div class="form-container street-address">
                <div class="form-element" v-bind:class="{error: errors.essay}">
                    <label for="essay">Essay (300 word limit)*</label>
                    <textarea name="essay" id="essay" rows="5" v-model="entry.essay"
                        v-on:input="clearError('essay')"></textarea>
                    <div v-if="errors.essay">
                        <p>{{ errors.essay[0] }}</p>
                    </div>
                     <div id="word-count" class="error-msg">
                        <p>Word count = {{ 300 - wordCount }} </p>
                    </div>
                </div>
            </div>
             <div class="form-container start uploader-container">
                <div class="form-element uploader" v-bind:class="{error: errors.media_url}">
                    <label>Video/Audio Upload* (2 minute limit, accepted file types are MP4, MOV, OGG, QT, MPEG, MPGA, MP3, WAV )</label>
                    <div class="name-container" v-bind:class="{error: errors.media_url}"> 
                        <div v-if="entry.file.length">
                            <p v-for="(file, index) in entry.file" :key="index">
                                {{ file.name }}
                            </p>
                        </div>
                        <div v-else>
                            <p class="video-name">Add your file here</p>
                        </div>
                        <input type="file" accept="video/ogg,video/quicktime,video/mp4,video.webm,audio/mp3,audio/ogg,audio/wav" id="media_url" name="media_url" class="media_url" ref="myFiles" @change="onChange" v-on:input="clearError('essay')"/>                     
                    </div>
                    <div v-if="errors.media_url" class="error-msg">
                        <p>{{ errors.media_url[0] }}</p>
                    </div>
                </div>
             </div>
            <div class="form-container tnc-container">
                <div class="form-element checkbox">
                    <input class="styled-checkbox" id="tnc" name="tnc" type="checkbox" v-bind:class="{error: errors.tnc}" v-model="entry.tnc" v-on:input="clearError('tnc')">
                    <label for="tnc" class="css-label">I agree to the <a href="#terms" class="underline show-popup"> official contest rules</a>.</label>
                    <div v-if="errors.tnc" class="error-msg">
                        <p>{{ errors.tnc[0] }}</p>
                    </div>
                </div>
                <div class="checkbox" >
                    <input class="styled-checkbox" id="optin" name="optin" type="checkbox" value="1" v-model="entry.optin">
                    <label for="optin" class="css-label">Yes, I would like to receive future communications via email from Nestle Professional. <a href="https://www.nestleprofessional.us/privacy-policy" target="_blank">Privacy Policy</a> </label>
                </div>
            </div>
            <div v-if="errors.error" class="error-msg">
                <p>{{ errors.error[0] }}</p>
                <br>
            </div>
            <div class="btn-container">
                <button class="button yellow small midsize" @click="submit($event)" :disabled="isSubmitting">{{ isSubmitting ? "Please wait" : "Submit Entry" }}
                    <i class="fa fa-spinner fa-spin"></i>
                </button>              
            </div>
            <!-- <p class="heading" ng-show="isRegisteredSuccessfully" ></p> -->
            <div class="form-container">
              
            </div>
        </div>
</template>

<script>
import TermsAndConditionsComponent from './TermsAndConditionsComponent.vue';
export default {
  components: {TermsAndConditionsComponent},
  props: {
    states: Object,
},
  data() {
    return {
        max: 300,
        entry: {
            first_name: '', 
            last_name: '', 
            title: '',
            business_name: '',
            type_of_operation: '',
            business_address: '',
            city: '',
            state_code: '',
            zip: '',
            distributor_name: '',
            email: '',
            phone: '',
            prize_thoughts: '',
            how_stouffer_helped: '',
            favorite_foodservice_product: '',
            essay: '',
            media_url: '',
            tnc: false,
            optin: false,
            file: ''
        },
        wordCount: 0,
        isSubmitting: false,
        errors: {},
        isFilePresent : false,
    };
  },
  watch: {
      'entry.essay': function() {
          this.wordCount = this.entry.essay.length ? this.entry.essay.match(/\w+/g).length : 0;  
      },
  },
  methods: {
     onChange(e) {
        this.entry.media_url = this.$refs.myFiles.files[0];
        this.entry.file = this.$refs.myFiles.files;
    },
    submit() {
        let that = this;

        if (that.isSubmitting) {
            return;
        }

        that.isSubmitting = true;

        that.clearAllErrors();
        that.isSuccessful   = false;
            
        let formData = new FormData();
        formData.append('first_name', that.entry.first_name);
        formData.append('last_name', that.entry.last_name);
        formData.append('title', that.entry.title);
        formData.append('business_name', that.entry.business_name);
        formData.append('type_of_operation', that.entry.type_of_operation);
        formData.append('business_address', that.entry.business_address);
        formData.append('city', that.entry.city);
        formData.append('state_code', that.entry.state_code);
        formData.append('zip', that.entry.zip);
        formData.append('distributor_name', that.entry.distributor_name);
        formData.append('email', that.entry.email);
        formData.append('phone', that.entry.phone);
        formData.append('prize_thoughts', that.entry.prize_thoughts);
        formData.append('how_stouffer_helped', that.entry.how_stouffer_helped);
        formData.append('favorite_foodservice_product', that.entry.favorite_foodservice_product);
        formData.append('essay', that.entry.essay);
        formData.append('media_url', that.entry.media_url);
        formData.append('tnc', that.entry.tnc);
        formData.append('optin', that.entry.optin);

        axios.post('/entry', formData, { headers: { 'content-type': 'multipart/form-data' }}).then((response) => {
            if (response.data.status === "success") {
                $.colorbox({ inline: true, width: '95%', maxWidth: '800', href: "#thankYou", onClosed: function () {location.reload(true);}});
                that.entry = {
                    first_name: '', 
                    last_name: '', 
                    title: '',
                    business_name: '',
                    type_of_operation: '',
                    business_address: '',
                    city: '',
                    state_code: '',
                    zip: '',
                    distributor_name: '',
                    email: '',
                    phone: '',
                    prize_thoughts: '',
                    how_stouffer_helped: '',
                    favorite_foodservice_product: '',
                    essay: '',
                    media_url: '',
                    file: '',
                    tnc: false,
                    optin: false,
                    file: ''
                };
            } else {
                that.errors = { error: [response.data.message] };
            }
            that.isSubmitting = false;
        }, (error) => {
            if (error.response.status === 422) {
            that.errors = error.response.data.errors;
             this.scrollToError();
            } else {
                that.errors = {
                    error: ["An error occurred, please try again later."],
                };
            }
            that.isSubmitting = false;
        });
    },
    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();;
        } else {
            return true;
        }
    },
    clearError(key) {
        this.errors.error = '';
        if (key == 'media_url' || key == 'essay') {
            this.errors.media_url = '';
            this.errors.essay = '';
        } else {
            this.errors[key] = '';
        }
    },

    clearAllErrors() {
        this.errors = {
            
        };
        this.successMessage = '';
    },
     scrollToError() {
        setTimeout(() => {
            this.isSubmitting = false;
            let scrollTo = $("#" + Object.keys(this.errors)[0]).offset().top - 100;
            $("html, body").animate(
                {
                    scrollTop: scrollTo,
                },
                "slow"
            );
        }, 300);
    },
  },
};
</script>

<style>
</style>
